@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.ant-form-item {
	margin-bottom: 1.25rem !important;
}

.ant-form-item-label {
	padding: 0 0 .25rem !important;
}

.ant-form-item-label label {
	font-family: "DM Sans" !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 24px !important;
}

.ant-select-selector {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #212121 !important;
}

.ant-select {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	/* border: 1px solid #212121 !important; */
}

.select-borderless .ant-select-selector {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #212121 !important;
}

.select-borderless .ant-select {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #212121 !important;
}


.ant-form-item-extra {
	color: #388E3C !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	font-family: "DM Sans" !important;
	line-height: 20px !important;
}

.ant-select .ant-select-arrow {
	color: #212121 !important;
	font-size: 16px !important;
}

.ant-input-affix-wrapper {
	border-radius: 50px;
	width: 293px;
	height: 50px;
	border: 1px solid #ECECEC;
	font-size: 18px;
	background: #fff !important;
}

.ant-input-affix-wrapper:focus {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-input-affix-wrapper-focused {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-input-affix-wrapper:hover {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-tabs-nav-wrap {
	padding: 0px 0px !important;
	background-color: inherit !important;
}

.ant-tabs-nav-list {
	/* width: 30%; */
	display: flex;
	justify-content: space-between;
}

.ant-tabs-ink-bar {
	display: none !important;
}

.ant-breadcrumb-link {
	color: var(--Icon-active, #388E3C);
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.25rem;
}

.ant-tabs-tab-active {
	display: flex !important;
	padding: 1rem 0.75rem !important;
	align-items: flex-start !important;
	gap: 0.5rem !important;
	border: 1px solid var(--Border-secondary, #ECECEC) !important;
	background: #FFF !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #388E3C) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-tabs-tab:hover>.ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #388E3C) !important;
}

.ant-tabs-tab:focus>.ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #388E3C) !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
	border: none !important;
}

.underlined_tab .ant-tabs-tab-active {
	display: flex !important;
	padding: 0 !important;
	/* padding-bottom: 1.12rem !important; */
	align-items: flex-start !important;
	gap: 0.5rem !important;
	border: none !important;
	border-bottom: 0.3125rem solid #388E3C !important;
	background: #FFF !important;
}

.underlined_tab .ant-tabs-tab {
	padding: 0px !important;
}

.underlined_tab .ant-tabs-tab-btn {
	color: var(--Text-Black, #333) !important;
	font-family: "Lexend" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-table-thead .ant-table-cell {
	color: var(--Text, #333) !important;
	font-family: "Lexend" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-table-cell {
	color: var(--Gray-900, #101828) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

td:first-child {
	color: var(--Gray-900, #101828) !important;
	font-family: "Lexend" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-tag {
	display: flex;
	width: 8.4375rem;
	padding: 0.75rem 1rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 1.25rem;
	border: 2px solid var(--Primary-Alert, #FFB201);
}

.ant-spin-dot-item{
	background-color: #FFF !important;
}

.ant-modal-header {
	display: none !important;
}

.ant-modal-close-x{
	display: none !important;
}

.ant-modal-content {
	border-radius: 0.875rem !important;
	background: #FFF !important;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}