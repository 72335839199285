@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@font-face {
    font-family: 'Satoshi';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.zambia_logo_text {
    width: 15rem;
    color: #2E7D32;
    font-family: "Open Sans";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    /* 153.846% */
}

.zambia_login_text {
    color: #4B5563;
    text-align: center;
    font-family: "DM Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
}

.login-form-item label {
    color: #4B5563;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.remember_text {
    color: #4B5563;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.or_text {
    color: #4B5563;
    text-align: center;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.forgot_text {
    color: #0057E3;
    font-family: "Lexend", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    /* 142.857% */
}

.dnt_have_account_text {
    color: #4B5563;
    text-align: center;
    font-family: Lexend;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.main_nav_text {
    color: #2E7D32;
    font-family: "Open Sans";
    font-size: 0.99219rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.02188rem;
}

.reg_number_text {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border-radius: 0.1875rem;
    background: rgba(87, 168, 99, 0.25);
    color: #212121;
    font-family: "DM Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
}

.heading_1 {
    color: var(--Text-primary, #212121);
    text-align: center;
    font-family: "Satoshi";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 2.7rem */
}

.heading_4 {
    color: #FFF;
    font-family: "Satoshi";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 135%;
}


.heading_5 {
    color: var(--text-black, #222);
    text-align: center;
    font-family: "DM Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 1.5rem */
}

.paragraph_1 {
    color: #FFF;
    font-family: "Lexend";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.paragraph_2 {
    color: var(--Gray-900, #101828);
    font-family: "Lexend";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    /* 150% */
}

.label_1 {
    color: var(--Text-primary, #212121);
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.label_2 {
    color: var(--Text-primary, #212121);
    font-family: "Lexend";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    /* 150% */
}

.Label_3 {
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

}

.view_profile_text {
    color: var(--Link-secondary, #3482FF);
    font-family: "Outfit", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.profile_text {
    color: var(--Gray-700, #344054);
    font-family: "Outfit", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.card-border {
    border: 1px solid #ECECEC;
}