@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

:root {
	--newBg: #F8F8F8;
	background-color: var(--newBg) !important;
	overflow: hidden;
}

html {
	background-color: var(--newBg) !important;
}

body {
	background-color: var(--newBg) !important;
}

table {
	border-radius: 0.5rem !important;
	border: 1px solid var(--Gray-200, #EAECF0) !important;
	background: var(--White, #FFF) !important;
	box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06) !important;
}

.cover-photo {
	position: relative;
	width: 17.01588rem;
	height: 5.75rem;
	flex-shrink: 0;
	border-radius: 0.5rem 0.5rem 0rem 0rem;
	background: rgba(56, 142, 60, 0.28);
	background-size: cover;
	height: 5.75rem;
	border-radius: 14px 14px 0px 0px;
}

.profile {
	position: absolute;
	width: 100px;
	height: 100px;
	bottom: -40px;
	left: 30%;
	border-radius: 50%;
	z-index: 10000;
	background-color: #FDFDFD;
}

.profile-name {
	font-size: 20px;
	color: #000;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.card-main {
	width: 100%;
	border-radius: 16px 16px 0px 0px;
	user-select: none;
}

.react-tel-input .form-control {
	height: 100% !important;
	width: 100% !important;
	border: none !important;
	outline: none !important;
	padding-left: 75px !important;
}

.flag-dropdown {
	padding: 10px !important;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile+label {
	width: 100%;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	overflow: hidden;
	padding: 0.625rem 1.25rem;
	/* 10px 20px */
}



.inputfile+label svg {
	height: 40px;
	vertical-align: middle;
	fill: currentColor;
	margin-top: -0.25em;
	margin-right: 0.25em;
}


/* style 2 */

.inputfile-2+label {
	color: #212121;
	border-radius: 6px;
	border: 1px solid currentColor;
}

.inputfile-2:focus+label,
.inputfile-2.has-focus+label,
.inputfile-2+label:hover {
	color: #212121;
}

.file {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.payment_card {
	display: flex;
	height: 4rem;
	width: 7.9375rem;
	padding: 0.625rem 0.75rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.375rem;
	border: 1px solid var(--Grey-Border, #E0E0E0);
	background: var(--Mono-White, #FFF);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
}

.payment_card_active {
	display: flex;
	height: 4rem;
	width: 7.9375rem;
	padding: 0.625rem 0.75rem;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 0.375rem;
	border: 2px solid var(--Stripe-blue, #0570DE);
	background: var(--Mono-White, #FFF);
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
}

.payment_card_title {
	color: var(--Label, #727F96);
	font-family: "Lexend";
	font-size: 0.8125rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.payment_card_title_active {
	color: var(--Stripe-blue, #0570DE);
	font-family: "Lexend";
	font-size: 0.8125rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.payment_card_svg svg>path {
	fill: #727F96 !important;
}

.payment_card_svg_active svg>path {
	fill: #0570DE !important;
}

.bank_payment_card {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	flex: 1 0 0;
	border-radius: 0.5rem;
	border: 1px solid var(--Border-Border---Secondary, #E0E0E0);
	background: #FFF;
}

/* HTML: <div class="loader"></div> */
.loader {
	width: 2rem;
	height: 2rem;
	--b: 5px;
	aspect-ratio: 1;
	border-radius: 51%;
	padding: 0px;
	background: conic-gradient(#0000 10%, #388E3C) content-box;
	-webkit-mask:
		repeating-conic-gradient(#0000 0deg, #000 1deg 20deg, #0000 21deg 36deg),
		radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
	-webkit-mask-composite: destination-in;
	mask-composite: intersect;
	animation: l4 1s infinite steps(10);
}

@keyframes l4 {
	to {
		transform: rotate(1turn)
	}
}

.profile_dropdown_card {
	display: flex;
	width: 12.6875rem;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 0.5rem;
	border: 1px solid var(--Gray-100, #F2F4F7);
	background: var(--White, #FFF);
}