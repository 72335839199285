@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.input-phone {
	display: flex;
	width: 100%;
	height: 3.25rem;
	/* padding: 0px 0px 0px 19px; */
	align-items: center;
	border-radius: 3px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:hover {
	border: 2px solid #388E3C47;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:focus {
	border: 2px solid #388E3C47;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input {
	max-width: 100%;
	width: 100%;
	height: 3.25rem;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #212121;

}

.input:hover {
	border: 1px solid #212121;

}

.input:focus {
	border: 1px solid #388E3C47;
}

.input-textarea {
	max-width: 100%;
	width: 100%;
	align-items: center;
	border-radius: 6px !important;
	border: 1px solid #212121 !important;
}

.input-textarea:hover {
	border: 1px solid #212121;
}

.input-textarea:focus {
	border: 1px solid #212121;
}

.input-login {
	display: flex;
	padding: 0.625rem 0.8125rem;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
	border-radius: 0.375rem;
	border: 1px solid #D4D4D8;
	background: #FFF;
}

.cstm-btn {
	width: 100%;
	display: flex;
	padding: 0.5625rem 1.25rem;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 624.9375rem;
	background: var(--Icon-active, #388E3C);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
	color: #FFF;
	text-align: center;
	font-family: "Lexend", sans-serif;
	font-size: 0.875rem !important;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem;
	/* 171.429% */
}

.cstm-btn-2 {
	display: flex;
	width: 400px;
	height: 2.8125rem;
	padding: 0.75rem 4.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	background: var(--colors-brand-primary-alt-500, #388E3C);
	color: var(--Text-Inverse-Primary, #FFF);
	font-family: "Lexend";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	/* 150% */
}

.cstm-btn-borderless {
	width: 100%;
	display: flex;
	padding: 0.5625rem 1.25rem;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 624.9375rem;
	background: #fff;
	border: none;
	color: #388E3C;
	text-align: center;
	font-family: "Lexend", sans-serif;
	font-size: 0.875rem !important;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem;
	/* 171.429% */
}

.green-border-btn {
	display: flex;
	padding: 0.5625rem 1.25rem;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 624.9375rem;
	border: 1px solid #2E7D32;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
	color: var(--Icon-active, #388E3C);
	text-align: center;
	font-family: "Lexend", sans-serif;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem;
	/* 171.429% */
}


.side-bar-scroll::-webkit-scrollbar-track {
	background-color: transparent;
}

.side-bar-scroll::-webkit-scrollbar-thumb {
	/* @apply bg-blu; */
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

.side-bar-scroll::-webkit-scrollbar-thumb:hover {
	/* @apply bg-blu */
}

.login-img {
	/* display: flex; */
	flex-shrink: 0;
	background: url(./assets/img/image1.png) rgba(0, 0, 0, 0.30);
	background-size: cover;
	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 120%;
}

.login-overlay {
	display: flex;
	width: 36.1875rem;
	height: 14.75rem;
	padding: 3.75rem 1.25rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	flex-shrink: 0;
	background: rgba(43, 43, 43, 0.72);
}

.zambia-logo {
	width: 3.01688rem;
	height: 3.25rem;
	flex-shrink: 0;
	object-fit: contain;
}


.services-btn {
	width: 100%;
	height: 64px;
	gap: 7px;
	border-radius: 8px 0px 0px 0px;
	opacity: 0px;
	display: flex;
	align-items: center;
	background-color: #F9FFF9;
	box-shadow: 0px 4px 4px 0px #0000001C;
	padding-left: 28.75px;
	padding-top: 26px;
	padding-bottom: 27px;
	color: #388E3C;
	font-family: "DM Sans" !important;
	font-size: 16px;
}

.history-btn {
	width: 100%;
	height: 64px;
	gap: 7px;
	border-radius: 8px 0px 0px 0px;
	border: 1px solid #ECECEC;
	opacity: 0px;
	color: #212121;
	padding-left: 28.75px;
	padding-top: 26px;
	padding-bottom: 27px;
	display: flex;
	align-items: center;

}

.service-card {
	min-width: 17.5rem;
	max-width: 100%;
	width: 17.5rem;
	/* min-height: 222px; */
	height: auto;
	border-radius: 13.4px;
	background: #fff;
	border: 1px solid #ECECEC
}

.service-line {
	height: 3px;
	width: 36px;
	background: #388E3C;
	/* transform: rotate(90deg); */
}

.black-nav {
	display: flex;
	width: 100%;
	padding: 1.4375rem 0rem;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	background: #2B2B2B;
}

.main_nav_btn {
	display: flex;
	height: 2.8125rem;
	padding: 0.75rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	color: var(--Text-Primary, #212121);
	text-align: right;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	/* 150% */
}

.view_btn {
	display: flex;
	width: 9.625rem;
	height: 3.125rem;
	padding: 0.75rem 4.625rem;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	border-radius: 1.75rem;
	border: 1px solid var(--Text-Secondary, #616161);
	background: var(--Button-outlined-surface, #FFF);
	color: var(--Text-secondary, #616161);
	font-family: "Lexend";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	/* 150% */
}

.application_details_header {
	width: 100%;
	display: flex;
	height: 4.25rem;
	padding: 0.75rem 1.5rem;
	align-items: center;
	gap: 0.75rem;
	align-self: stretch;
	border-bottom: 1px solid var(--Gray-200, #EAECF0);
	background: #FAFAFA;
}

.application_details_body {
	height: 4.25rem;
	padding: 0.75rem 1.5rem;
	gap: 0.75rem;
	border-bottom: 1px solid var(--Gray-200, #EAECF0);
}